import { IPaystackParams } from './types';

const doPaystackPayment = ({
  key,
  email,
  amount,
  currency,
  ref,
  onSuccess,
  onClose,
  subaccount,
}: IPaystackParams) => {
  const paystack = new (window as any).PaystackPop();
  paystack.checkout({
    key,
    email,
    amount: amount * 100,
    currency,
    ref,
    subaccount,
    onSuccess: (response: any) => {
      onSuccess(response.transaction);
    },
    onClose: () => {
      if (onClose) {
        onClose();
      }
    },
  });
};

export default doPaystackPayment;
