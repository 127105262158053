import { AxiosResponse } from 'axios';
import { PAYMENT_PROVIDER } from './types/enums';
import {
  ICancelTransactionPayload,
  ICheckoutProps,
  ITransactionInitiationPayload,
  ITransactionInitiationResponse,
  IVerifyTransactionPayload,
} from './types';
import axiosInstance from './utils/axiosInstance';
import doPaystackPayment from './utils/paystack';
import Cart from './components/Cart';
import { formatCurrency } from 'utils';
import { useState } from 'react';

function App({
  customerEmail,
  customerPhone,
  cartItems,
  customerId,
  amount,
  currency,
}: ICheckoutProps) {
  const [isLoading, setLoading] = useState(false);
  const cancelTransaction = async (payload: ICancelTransactionPayload) => {
    try {
      await axiosInstance.post<ICancelTransactionPayload, {}>(
        '/transactions/cancellations',
        payload
      );
      window.parent.postMessage({ message: 'closeCeviantSDK' }, '*');
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const verifyTransaction = async (payload: IVerifyTransactionPayload) => {
    try {
      await axiosInstance.get('/transactions/verifications', {
        params: payload,
      });
      window.parent.postMessage(
        {
          message: 'ceviantPaymentSuccess',
          payload: payload.ceviantTransactionId,
        },
        '*'
      );
      setLoading(false);
    } catch (error) {}
  };

  const initiateTransaction = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post<
        ITransactionInitiationPayload,
        AxiosResponse<ITransactionInitiationResponse>
      >('/transactions', {
        provider: PAYMENT_PROVIDER.PAYSTACK,
        customerEmail,
        customerPhone,
        amount: amount * 100,
        currency,
        customerId,
        lineItems: cartItems.map((item) => ({
          ...item,
          totalPrice: item.totalPrice * 100,
          unitPrice: item.unitPrice * 100,
        })),
      });
      if (response.data) {
        doPaystackPayment({
          key: response.data.providerPublicKey,
          amount,
          email: customerEmail,
          currency: 'NGN',
          ref: response.data.ceviantTransactionId,
          subaccount: response.data.subAccountId,
          onSuccess: (reference) => {
            verifyTransaction({
              ceviantTransactionId: response.data.ceviantTransactionId,
              providerTransactionId: reference,
              amount,
              provider: PAYMENT_PROVIDER.PAYSTACK,
            });
          },
          onClose: () => {
            cancelTransaction({
              ceviantTransactionId: response.data.ceviantTransactionId,
              amount,
              provider: PAYMENT_PROVIDER.PAYSTACK,
            });
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div className="flex  w-full h-screen max-h-screen bg-Primary-200/80 justify-center pt-10">
      <span className="loader absolute my-auto top-[50%]"></span>
      {!isLoading && (
        <>
          <div className="flex flex-col py-6 space-y-5 rounded-xl bg-white min-w-[617px] mb-auto z-10">
            <div className="flex justify-between w-full py-4 px-6">
              <img src="/ceviant_logo.svg" alt="logo" />
              <p className="font-500 text-sm text-SecondaryDarkBlue">
                Confirm your order and make payments here
              </p>
            </div>
            <Cart items={cartItems} />
            <hr />
            <div className="flex flex-col space-y-4 px-6">
              <div className="flex justify-between mb-4">
                <p className="text-base font-500 text-gray-90">Total Amount</p>
                <p className="text-base font-700 text-SecondaryDarkBlue">
                  {formatCurrency(amount, 'NGN')}
                </p>
              </div>
              <button
                disabled={isLoading}
                onClick={initiateTransaction}
                className="bg-blue-500 px-2 w-full py-3 rounded text-white flex items-center justify-center space-x-2 disabled:bg-gray-400 disabled:cursor-not-allowed cursor-pointer"
              >
                <span>Pay {formatCurrency(amount, 'NGN')}</span>
                {isLoading && <span className="loader"></span>}
              </button>
            </div>
          </div>
          <button
            className="self-start cursor-pointer mx-1"
            onClick={() =>
              window.parent.postMessage({ message: 'closeCeviantSDK' }, '*')
            }
          >
            <svg
              viewBox="0 0 512 512"
              fill="#b42318"
              height="1.5em"
              width="1.5em"
            >
              <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm86.63 272L320 342.63l-64-64-64 64L169.37 320l64-64-64-64L192 169.37l64 64 64-64L342.63 192l-64 64z" />
            </svg>
          </button>
        </>
      )}
    </div>
  );
}

export default App;
