export const formatCurrency = (
  amount: number | string | undefined | null,
  currency: string
): string => {
  if (!amount) return 'N/A';
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount);
};
