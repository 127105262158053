import { ICartItem } from 'types';
import { formatCurrency } from '../../utils';
const Cart = ({ items }: { items: ICartItem[] }) => {
  return (
    <div className="flex flex-col w-full m-0">
      <div className="flex bg-gray-100 px-6 py-4">Cart Items</div>
      <div className="flex flex-col w-full px-6 mt-4 lg:max-h-[306px] space-y-6 overflow-y-scroll">
        {items.map((item) => (
          <div className="flex justify-between" key={item.id}>
            <div className="flex flex-col space-y-1">
              <div className="text-sm font-semibold text-gray-90">
                {item.description}
              </div>
              <div className="text-sm text-gray-500">
                Quantity: {item.quantity}
              </div>
            </div>
            <div className="text-base font-500 text-gray-90">
              {formatCurrency(item.totalPrice, 'NGN')}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Cart;
